body {
  margin: 0;
  padding: 0;
}

/* Define Font */
h1,
h2,
h3,
h4,
h5 {
  font-family: futura-pt, sans-serif;
}

/*  */
/* inputs */
/*  */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

/*  */
/* buttons, inputs */
/*  */
a, div, button:focus, input[type=submit], .w-button {
  outline: 0;
}

/* Disabled save btn styles for admin school applications */
.applications .btn-solid-green.w-button:disabled {
  background-color: #cccccc;
  border-color: #cccccc;
}

select {
  font-family: futura-pt, sans-serif;
}

/*  */
/* prevent selecting text */
/*  */
.noselect {
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/*  */
/* radio buttons checked style */
/*  */
input[type="radio"]:checked + label {
  background-image: url("../images/filled-radio.svg");
}

/*  */
/* checkbox checked style */
/*  */
input[type="checkbox"]:checked + label {
  background-image: url("../images/filled-checkbox.svg");
}

.css-1pcexqc-container {
  margin-bottom: 8px;
}

/*  */
/* add cursor pointer to modified webflow links  */
/*  */
.w-dropdown-link {
  cursor: pointer;
}

/*  */
/* disabled input styles */
/*  */
input:disabled ~ .radio-label,
input:disabled ~ .checkbox-label {
  filter: gray;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: .65;
}

/*  */
/* disabled link styles */
/*  */
.disabled-link {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}

.disabled-link-button {
  pointer-events: none;
  cursor: default;
  filter: grayscale(1);
  user-select: none;
}

/*  */
/* Application Team Member overrides */
/*  */
.agreement-div {
  overflow-y: scroll;
  margin-bottom: 0px;
}
.agreement-confirm {
  margin-top: 30px;
}

.agreement-div::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.agreement-div::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    box-shadow: 0 0 1px rgba(255,255,255,.5);
}

.agreement-indent {
  padding-left: 20px;
}

.agreement-bottom-padding {
  padding-bottom: 10px;
}

.agreement-bold {
  font-weight: bolder;
}

.agreement-bold-underline {
  font-weight: bolder;
  text-decoration: underline;
}

.roster-table.team-members h4 {
  font-size: 14px;
}
.roster-table.team-members .column.teachers {
  width: 13%;
  min-width: 50px;
}

.roster-table.team-members .column.teachers.email {
  width: 28%;
}

.roster-table.team-members .column.teachers.icon {
  width: initial;
  min-width: 25px;
}

/*  */
/* React Confirm Alert */
/*  */
.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999999;
}

.react-confirm-alert-body {
  border-radius: 0;
  color: #000;
}

.react-confirm-alert-button-group button {
  display: inline-block;
  padding: 9px 15px;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  border-radius: 25px;
  background-color: #76b900;
  color: #fff;
  line-height: 16px;
  font-weight: 700;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
}

.react-confirm-alert-button-group button:first-child {
  margin-right: 15px;
  border-style: solid;
  border-width: 2px;
  border-color: #76b900;
  background-color: #fff;
  color: #76b900;
}

/*  */
/* Educator Dashboard */
/*  */
.show-hide-icon {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.is-open .show-hide-icon {
  transform: rotate(180deg);
}

.student-distribution-report {
  max-height: 342px;
  overflow: auto;
}

.btn-green {
  margin: 5px 0;
}

.btn-green.space {
  margin-right: 10px;
}

.btn-green.download {
  color: #442c79;
  border-color: #442c79;
}

.students-modal {
  max-width: 700px;
  width: 100%;
  background-color: white;
  opacity: 1;
  outline: none;
}

.modal-black-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.accordion .link-block-5.w-inline-block {
  background: transparent;
}

.active-details, .submitted-details {
  padding-bottom: 25px !important;
}

.field-textarea {
  width: 100%;
}

.report-table-col.testimonial {
  max-width: 170px;
  white-space: nowrap;
}

.report-table-col.testimonial > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.rotate {
  transform: rotate(180deg);
}

.icon-placeholder {
  width: 30px;
  margin-right: 10px;
}

.padding-left {
  padding-left: 50px
}

.blank-state {
  flex-direction: column;
  justify-content: center;
  opacity: 0.3;
  text-align: center;
  padding: 60px;
  font-family: futura-pt, sans-serif;
}

.blank-state > h1 {
  font-weight: bold;
  letter-spacing: 1px;
}

.blank-state > h3 {
  margin-top: 0;
  font-weight: 400;
}

.students-modal textarea,
.field-textarea.comment {
  resize: none;
}

.error-label {
  font-size: 10px;
  color: #db0c41;
}

/*  */
/* IE Fixes */
/*  */
.datepicker-select-button {
  background-color: transparent;
}

.school-sites-dropdown {
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.close.w-inline-block {
  background-color: transparent;
}

.export-reports-div {
  width: 300px;
}

/*  */
/* Admin */
/*  */
.rounded-wrapper {
  position: relative;
  min-height: 344px;
}

.admin-reports .report-table-col.testimonial {
  max-width: 68px;
}

.text-force-wrap {
  word-wrap: break-word;
}

/* Pagination Styles */
.pagination-div {
  margin: 30px 0;
  justify-content: center;
  width: initial;
}

.pagination-div .page-link {
  background-color: transparent;
}

/*  */
/* Admin School Table */
/*  */
.date-export.admin-schools {
  display: inline-block;
}

.date-export.admin-schools:nth-of-type(1):not(.backpack-buddy) {
  float: right;
}

.date-export.admin-schools.backpack-buddy:nth-of-type(2) {
  float: right;
}

.table-col {
  text-overflow: ellipsis;
  overflow: hidden;
}

.admin-school-select-wrapper {
  position: relative;
}

.admin-school-select-wrapper > img {
  position: absolute;
  right: 0;
  pointer-events: none;
}

.admin-school-select {
  border: 0;
  outline: 0;
  background: initial;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px;
}

.admin-school-select::-ms-expand {
  display: none;
}

.rt-table {
  position: relative;
}

.schools-table .rt-thead.-header {
  margin-top: 80px;
}

.rt-thead.-filters {
  position: absolute;
  top: -70px;
  width: 100%;
}

.rt-thead.-filters .filters {
  flex-wrap: initial;
}

.rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block {
  width: initial;
  display: flex;
  flex-grow: 1;
}

.rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:last-of-type {
  padding-right: 0;
}

.rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:last-of-type .filter-select.w-select {
  margin-right: 0;
}

.rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block .filter-select {
  width: 100%;
  margin-bottom: 0;
  margin-right: 0;
}

.rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block {
  padding-left: initial;
}

@media (max-width: 767px) {
  .rt-thead.-filters .filters {
    overflow: scroll;
  }

  .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block {
    padding-right: 5px;
  }
}

.rt-th.table-col.w-inline-block.rt-resizable-header.-sort-asc,
.rt-th.table-col.w-inline-block.rt-resizable-header.-sort-desc {
  position: relative;
}

.ReactTable .rt-th.-sort-asc .rt-resizable-header-content:after {
  padding-left: 5px;
  content: '↓';
}

.ReactTable .rt-th.-sort-desc .rt-resizable-header-content:after {
  padding-left: 5px;
  content: '↑';
}

/*  */
/* Admin Users Table */
/*  */
.users-table .table-col.fnln-cell,
.users-table .table-col.fnln-users {
  width: 16%;
  color: #442c79;
}

.search-bar.w-input.users-table {
  width: 60%;
}

.users-table .rt-thead.-filters {
  position: absolute;
  top: -65px;
  width: 40%;
  right: 0px;
}

.users-table .filters {
  justify-content: flex-end;
}

.users-table .rt-thead.-header {
  margin-top: 0;
}

.users-table .rt-tr.filters > div:nth-of-type(1),
.users-table .rt-tr.filters > div:nth-of-type(2),
.users-table .rt-tr.filters > div:nth-of-type(3) {
  display: none;
}

.users-table .w-select {
  border: 0;
  outline: 0;
  background: initial;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px;
  display: inline-block;
  height: 55px;
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
}

.users-table .rt-tr.filters .filter-select {
  margin-right: 0;
  width: 48%;
}

.users-table .rt-tr.filters .filter-select:nth-of-type(6) {
  padding-right: 0;
}

@media (max-width: 767px) {
  .users-table .rt-thead.-header {
    margin-top: 70px;
  }
  .search-bar.w-input.users-table {
    width: 100%;
  }
  .users-table .rt-thead.-filters .filters {
    overflow: initial;
  }
}

/*  */
/* Admin Districts Table */
/*  */
.districts-table .table-col.fnln-cell,
.districts-table .table-col.fnln-users {
  width: 40%;
  color: #442c79;
}
.districts-table .table-col.fnln-cell:last-child,
.districts-table .table-col.fnln-users:last-child {
  width: 18%;
}

.search-bar.w-input.districts-table {
  width: 80%;
}

.districts-table .rt-thead.-filters {
  position: absolute;
  top: -65px;
  width: 40%;
  right: 0px;
}

.districts-table .filters {
  justify-content: flex-end;
}

.districts-table .rt-thead.-header {
  margin-top: 0;
}

.districts-table .rt-tr.filters > div:nth-of-type(1),
.districts-table .rt-tr.filters > div:nth-of-type(2) {
  display: none;
}

.districts-table .w-select {
  border: 0;
  outline: 0;
  background: initial;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 25px;
  display: inline-block;
  height: 55px;
  margin-right: 5px;
  margin-bottom: 0px;
  font-family: 'Open Sans', sans-serif;
}

.districts-table .rt-tr.filters .filter-select {
  margin-right: 0;
  width: 48%;
}

.districts-table .rt-tr.filters .filter-select:nth-of-type(6) {
  padding-right: 0;
}

@media (max-width: 767px) {
  .districts-table .rt-thead.-header {
    margin-top: 70px;
  }
  .search-bar.w-input.districts-table {
    width: 100%;
  }
  .districts-table .rt-thead.-filters .filters {
    overflow: initial;
  }
}

/*  */
/* Admin Emails */
/*  */
.email-settings-edit-link {
  background-color: transparent;
  text-decoration: underline;
}

.email-settings-edit-link.dirty {
  background-color: #FFD66C;
}

.btn-solid-green.bottom-pad.w-button[disabled] {
  background-color: lightgray;
  border-color: lightgray;
}

.edit-link.email-settings-edit-link.warning {
  background-color: #ffeff3;
  border-radius: 3px;
}

.emails-unsaved-warning {
  margin-left: 10px;
  font-size: 14px;
}

.rich-editor-wrapper .rdw-editor-toolbar {
  margin-bottom: 0;
  border-bottom-color: rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #ccc;
}

.rich-editor-wrapper .rdw-editor-main {
  border-top: solid 1px rgba(0, 0, 0, 0.05);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.merge-tags {
  font-size: 14px;
}

/*  */
/* User Detail */
/*  */
.admin-reset-password-link {
  text-decoration: underline;
  font-family: futura-pt,sans-serif;
  cursor: pointer;
}

.remove-school {
  position: initial;
  text-decoration: underline;
}

/*  */
/* Toast Styles */
/*  */
.Toastify__toast-container {
  z-index: 999999;
}

.toast {
  min-height: 50px;
  border-radius: 2px;
  background-color: #76b900;
  color: #FFF;
  font-family: futura-pt-bold, sans-serif;
}

/*  */
/* Documents Table */
/*  */
.rt-th.doc-table-col.w-inline-block.rt-resizable-header.-sort-asc,
.rt-th.doc-table-col.w-inline-block.rt-resizable-header.-sort-desc {
  position: relative;
}

.file-upload-label {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*  */
/* Signature Page */
/*  */
.purple-headline.half-width.signature {
  width: 575px;
}

.btn-flex-container {
  display: flex;
}

/*  */
/* BACKPACK BUDDY SCHOOL TABLE */
/*  */
.schools-table.backpack-buddy .table-col.schools-header {
  width: 9%;
  min-width: 80px;
  color: #442c79
}
.schools-table.backpack-buddy .table-col.schools-cells {
  width: 9%;
  min-width: 80px;
}

.schools-table.backpack-buddy .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(1),
.schools-table.backpack-buddy .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(2),
.schools-table.backpack-buddy .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(9) {
  display: none;
}

/*  */
/* SCHOOL MARKET SCHOOL TABLE */
/*  */
.schools-table.school-market .table-col.schools-header {
  width: 9%;
  min-width: 80px;
  color: #442c79
}
.schools-table.school-market .table-col.schools-cells {
  width: 9%;
  min-width: 80px;
}

.schools-table.school-market .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(1),
.schools-table.school-market .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(2),
.schools-table.school-market .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(7),
.schools-table.school-market .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(8),
.schools-table.school-market .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(9) {
  display: none;
}

/*  */
/* TEACHERS AID SCHOOL TABLE*/
/*  */
.schools-table.teachers-aid .table-col.schools-header {
  width: 9%;
  min-width: 80px;
  color: #442c79
}
.schools-table.teachers-aid .table-col.schools-cells {
  width: 9%;
  min-width: 80px;
}

.schools-table.teachers-aid .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(1),
.schools-table.teachers-aid .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(2),
.schools-table.teachers-aid .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(7),
.schools-table.teachers-aid .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(8),
.schools-table.teachers-aid .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(12) {
  display: none;
}

/*  */
/* KIDS CAFE SITE TABLE*/
/*  */
.schools-table.kids-cafe .table-col.schools-header {
  width: 11%;
  min-width: 80px;
  color: #442c79
}
.schools-table.kids-cafe .table-col.schools-cells {
  width: 11%;
  min-width: 80px;
}

.schools-table.kids-cafe .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(1),
.schools-table.kids-cafe .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(2),
.schools-table.kids-cafe .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(5),
.schools-table.kids-cafe .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(6),
.schools-table.kids-cafe .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(7)  {
  display: none;
}

/*  */
/* PARTNER SERVICES ORG TABLE*/
/*  */
.schools-table.partner-services .table-col.schools-header {
  width: 14%;
  min-width: 80px;
  color: #442c79
}
.schools-table.partner-services .table-col.schools-cells {
  width: 14%;
  min-width: 80px;
}

.schools-table.partner-services .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(1),
.schools-table.partner-services .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(2),
.schools-table.partner-services .rt-thead.-filters .rt-th.table-col.schools-header.w-inline-block:nth-child(10) {
  display: none;
}

/* Adding delete button to school reports div */
.admin-reports .export-reports-div, .date-export .export-reports-div {
  width: 360px;
}
.admin-reports .submit-button, .date-export .submit-button {
  margin-right: 10px;
}
.admin-reports .submit-button:disabled, .date-export .submit-button:disabled {
  opacity: 0.5;
}
.crud-action-icon-wrapper.delete-students {
  float: right;
}
.crud-action-icon-wrapper.delete-students:disabled {
  opacity: 0.25;
}
.crud-action-icon-wrapper.delete-students {
  opacity: 1;
}
.crud-action-icon-wrapper.delete-students img {
  vertical-align: top;
}
.report-table-col.actions.distribution {
  display: flex;
}
.report-table-col.actions.distribution .checkbox-field {
  padding-left: 25px;
  margin-bottom: 0px;
  padding-top: 4px;
}

.section-dashboard .program-listing:last-child {
  border-bottom-width: 0px;
}

/* Fix some links ie. 'Return to Login' so they are the width of their content */
.max-content {
  width: max-content;
}
/* Fix School Market application summer school question */
.radio-field.inline-radio.summer-school:last-child {
  width: auto;
}
/* Fix padding above Tecahers Aid declaration */
.checkbox-inline.w-checkbox.margin-top-30 {
  margin-top: 30px;
}

/* Adding delete icon to teachers list */
.column.teachers {
  width: 19%;
}
.column.teachers.delete {
  width: 5%;
}
.padding-left-ten {
  padding-left: 10px;
}
.column.teachers.delete.icon {
  text-align: right;
}

/* Disabled cursor styles on application status page */
.section-begin-application .btn-solid-grey.w-button.no-cursor{
  cursor: default;
}

/* fix alignment on educator backpack buddy profile application section */
.apply-section {
  display: flex;
  flex-wrap: wrap;
  height: 220px;
}

.apply-section-button {
  display: flex;
  align-self: flex-end;
}

/* Teacher Subjects Multiselect */
.multi-select-box {
  min-height: 55px;
  height: auto;
}

.teacher-subject-dropdown {
  height: inherit !important;
  border: transparent !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.teacher-subject-dropdown .rw-input-reset {
  height: 51px !important;
}

.teacher-subject-dropdown .rw-multiselect-tag {
  vertical-align: middle;
  height: 40px;
  margin: initial;
  padding: initial;
  margin: 3px;
  padding: 5px 15px;
  border-radius: 100px;
  background-color: rgba(118, 185, 0, 0.1);
  border: none;
}

.teacher-subject-dropdown .rw-multiselect-tag span {
  font-family: 'Open Sans', sans-serif;
  color: #76b900;
  font-weight: 700;
  font-size: 14px;
}

.rw-multiselect-tag-btn.rw-btn.rw-btn-select {
  margin-left: 6px;
  font-size: 14px;
  transform: scale(1.5);
} 

/* Participants Age Range checklist */
.checkbox-inline._12 {
  width: 12.5%;
  margin-right: 0px;
}

/* Backpack Buddy Notification Banner */
.section.notification {
  margin-top: 67px;
}
.section.notification ~ .section-sites {
  padding-top: 10px;
}

.section.notification ~ .section-content {
  padding-top: 50px;
}

/* Email edit modal */
.email-edit-modal {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
  padding: 25px 25px 10px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(74, 74, 74, 0.5);
}
.textarea {
  min-height: 150px;
}

/* Edit form dropdown select */
.field-combo-wrapper.dropdown-select {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

/* School market start page */
.width-70 {
  width: 70%;
}
.schoolmarket-start {
  height: auto;
  padding-top: 100px;
}

/* School Market covid disclaimer */
.market-type-disclaimer {
  font-size: 14px;
  line-height: inherit;
  font-style: italic;
  margin-bottom: 5px;
}

/* ADMIN APPLICATION VIEW LINK */
.view-application {
  text-decoration: underline;
  color: #442c79;
  cursor: pointer;
}

/* SCHOOL MARKET SIGNATURE AGREEMENT LINES */
.signature-placeholder {
    border: 0;
    border-bottom: 2px solid rgba(197, 197, 197, 0.3);
    height: 55px;
    width: 100%;
    margin-bottom: 20px;
    background-color: initial;
}

/* SIGNATURE PAGE PRINT STYLES */
@media print {
   @page { margin: .5cm; }
  * {
    -webkit-print-color-adjust: exact !important;
  }
  .section-topnav,
  .form-save.w-button {
    display: none !important;
  }
  ._1300-container {
    max-width: 100%;
    width: 100%;
  }
  .purple-headline.boxed-in {
    margin-top: 10px;
    line-height: 20px;
  }
  p,
  .agreement-site-info * {
    font-size: 12px;
    line-height: 16px;
  }
  .agreement-div.scroll {
    height: auto;
  }
  .section-content {
    padding: 0;
  }
  .agreement-div {
    margin-top: 10px;
    padding: 20px 10px;
  }
  .agreement-div * {
    font-size: 10px;
    line-height: 16px;
  }
  .agreement-list-item {
    margin-bottom: 10px;
    margin-left: 20px;
  }
  h2 {
    font-size: 26px;
  }
  h4 {
    font-size: 16px;
  }
  .text-field.w-input {
    height: 35px;
    margin-bottom: 10px;
  }
  .field-label {
    font-size: 12px;
    margin-top: 15px;
  }
}

/* SKM css code to fix registration pages */
.registration-flex-wrapper {
  height: 100vh;
}
.registration-flex-wrapper .login-tabs-content {
  margin-top: 5px;
}
.registration-flex-wrapper .login-tabs-content h2 {
  margin-bottom: 40px;
}
.registration-flex-wrapper .login-tabs-content .sign-up .field-label {
  margin-top: 5px;
}
.registration-flex-wrapper .login-tabs-content .login-form {
  margin-top: 0px;
}


.register-print-info {
  display: none;
}

@media print {
  header,
  footer,
  #map-container,
  .register-info {
  }

  .register-print-info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
}

.pantry-info-title {
  font-size: 16px;
  font-weight: 500;
}



.pantry-info-wrapper {
  display: flex;
  flex-direction: column;
}

.pantry-info {
  display: flex;
}
