
.s1 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10.5pt; }
.s2 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 10.5pt; }
.s4 { color: black; font-family:"Times New Roman", serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10.5pt; }
h3 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 10.5pt; }
.p, p { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; margin:0pt; }
h2 { color: black !important; font-family:Arial, sans-serif !important; font-style: normal !important; font-weight: bold !important; text-decoration: none !important; font-size: 11pt !important; }
.s5 { color: black; font-family:Arial, sans-serif; font-style: italic; font-weight: normal; text-decoration: none; font-size: 11pt; }
a { color: #467885; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 11pt; }
.s6 { color: #467885; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 11pt; }
.s7 { color: #1B1B1B; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
.s8 { color: #0A779F; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 11pt; }
.s9 { color: #0A779F; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 11pt; }
.s10 { color: #1B1B1B; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 11pt; }
h1 { color: black !important; font-family:Arial, sans-serif !important; font-style: normal !important; font-weight: bold !important; text-decoration: none !important; font-size: 12pt !important; }
.s13 { color: #0462C1; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt; }
.s14 { color: #467885; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt; }
.s15 { color: black; font-family:Calibri, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 11pt; }
.s16 { color: #467885; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 11pt; }
.s18 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
.s19 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: underline; font-size: 12pt; }
.s20 { color: #221F1F; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
.s21 { color: #221F1F; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 7.5pt; vertical-align: 3pt; }
.s22 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 7.5pt; vertical-align: 3pt; }
.s23 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
.s25 { color: #0462C1; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: underline; font-size: 10pt; }
.s26 { color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
.s27 { color: #221F1F; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 9.5pt; }
li {display: block; }
#l1 {padding-left: 0pt;counter-reset: c1 1; }
#l1> li>*:first-child:before {counter-increment: c1; content: counter(c1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10.5pt; }
#l1> li:first-child>*:first-child:before {counter-increment: c1 0;  }
li {display: block; }
#l2 {padding-left: 0pt;counter-reset: d1 1; }
#l2> li>*:first-child:before {counter-increment: d1; content: counter(d1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l2> li:first-child>*:first-child:before {counter-increment: d1 0;  }
#l3 {padding-left: 0pt;counter-reset: e1 1; }
#l3> li>*:first-child:before {counter-increment: e1; content: counter(e1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l3> li:first-child>*:first-child:before {counter-increment: e1 0;  }
#l4 {padding-left: 0pt;counter-reset: f1 1; }
#l4> li>*:first-child:before {counter-increment: f1; content: counter(f1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l4> li:first-child>*:first-child:before {counter-increment: f1 0;  }
#l5 {padding-left: 0pt;counter-reset: f2 1; }
#l5> li>*:first-child:before {counter-increment: f2; content: counter(f2, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l5> li:first-child>*:first-child:before {counter-increment: f2 0;  }
#l6 {padding-left: 0pt;counter-reset: f3 1; }
#l6> li>*:first-child:before {counter-increment: f3; content: counter(f3, lower-roman)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l6> li:first-child>*:first-child:before {counter-increment: f3 0;  }
#l7 {padding-left: 0pt;counter-reset: f2 1; }
#l7> li>*:first-child:before {counter-increment: f2; content: counter(f2, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l7> li:first-child>*:first-child:before {counter-increment: f2 0;  }
#l8 {padding-left: 0pt;counter-reset: g1 1; }
#l8> li>*:first-child:before {counter-increment: g1; content: counter(g1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l8> li:first-child>*:first-child:before {counter-increment: g1 0;  }
#l9 {padding-left: 0pt;counter-reset: h1 1; }
#l9> li>*:first-child:before {counter-increment: h1; content: counter(h1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l9> li:first-child>*:first-child:before {counter-increment: h1 0;  }
#l10 {padding-left: 0pt;counter-reset: i1 1; }
#l10> li>*:first-child:before {counter-increment: i1; content: counter(i1, decimal)". "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l10> li:first-child>*:first-child:before {counter-increment: i1 0;  }
#l11 {padding-left: 0pt;counter-reset: j1 1; }
#l11> li>*:first-child:before {counter-increment: j1; content: counter(j1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l11> li:first-child>*:first-child:before {counter-increment: j1 0;  }
#l12 {padding-left: 0pt;counter-reset: k1 1; }
#l12> li>*:first-child:before {counter-increment: k1; content: counter(k1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l12> li:first-child>*:first-child:before {counter-increment: k1 0;  }
#l13 {padding-left: 0pt;counter-reset: l1 1; }
#l13> li>*:first-child:before {counter-increment: l1; content: counter(l1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l13> li:first-child>*:first-child:before {counter-increment: l1 0;  }
#l14 {padding-left: 0pt;counter-reset: l2 1; }
#l14> li>*:first-child:before {counter-increment: l2; content: counter(l2, decimal)". "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l14> li:first-child>*:first-child:before {counter-increment: l2 0;  }
#l15 {padding-left: 0pt;counter-reset: l3 1; }
#l15> li>*:first-child:before {counter-increment: l3; content: counter(l3, decimal)". "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l15> li:first-child>*:first-child:before {counter-increment: l3 0;  }
#l16 {padding-left: 0pt;counter-reset: m1 1; }
#l16> li>*:first-child:before {counter-increment: m1; content: counter(m1, decimal)" "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l16> li:first-child>*:first-child:before {counter-increment: m1 0;  }
#l17 {padding-left: 0pt;counter-reset: m2 1; }
#l17> li>*:first-child:before {counter-increment: m2; content: counter(m1, decimal)"."counter(m2, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l17> li:first-child>*:first-child:before {counter-increment: m2 0;  }
#l18 {padding-left: 0pt;counter-reset: d2 1; }
#l18> li>*:first-child:before {counter-increment: d2; content: counter(d1, decimal)"."counter(d2, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l18> li:first-child>*:first-child:before {counter-increment: d2 0;  }
li {display: block; }
#l19 {padding-left: 0pt;counter-reset: n1 3; }
#l19> li>*:first-child:before {counter-increment: n1; content: counter(n1, decimal)" "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l19> li:first-child>*:first-child:before {counter-increment: n1 0;  }
#l20 {padding-left: 0pt;counter-reset: n2 1; }
#l20> li>*:first-child:before {counter-increment: n2; content: counter(n1, decimal)"."counter(n2, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l20> li:first-child>*:first-child:before {counter-increment: n2 0;  }
#l21 {padding-left: 0pt;counter-reset: n3 1; }
#l21> li>*:first-child:before {counter-increment: n3; content: counter(n1, decimal)"."counter(n2, decimal)"."counter(n3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l21> li:first-child>*:first-child:before {counter-increment: n3 0;  }
#l22 {padding-left: 0pt;counter-reset: n4 1; }
#l22> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l22> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l23 {padding-left: 0pt;counter-reset: n4 1; }
#l23> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l23> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l24 {padding-left: 0pt;counter-reset: n4 1; }
#l24> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l24> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l25 {padding-left: 0pt;counter-reset: n4 1; }
#l25> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l25> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l26 {padding-left: 0pt;counter-reset: n3 1; }
#l26> li>*:first-child:before {counter-increment: n3; content: counter(n1, decimal)"."counter(n2, decimal)"."counter(n3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l26> li:first-child>*:first-child:before {counter-increment: n3 0;  }
#l27 {padding-left: 0pt;counter-reset: n4 1; }
#l27> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l27> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l28 {padding-left: 0pt;counter-reset: n4 1; }
#l28> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l28> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l29 {padding-left: 0pt;counter-reset: n4 1; }
#l29> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l29> li:first-child>*:first-child:before {counter-increment: n4 0;  }
#l30 {padding-left: 0pt;counter-reset: n5 1; }
#l30> li>*:first-child:before {counter-increment: n5; content: counter(n5, lower-roman)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l30> li:first-child>*:first-child:before {counter-increment: n5 0;  }
#l31 {padding-left: 0pt;counter-reset: o1 1; }
#l31> li>*:first-child:before {counter-increment: o1; content: counter(o1, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l31> li:first-child>*:first-child:before {counter-increment: o1 0;  }
#l32 {padding-left: 0pt;counter-reset: n3 1; }
#l32> li>*:first-child:before {counter-increment: n3; content: counter(n1, decimal)"."counter(n2, decimal)"."counter(n3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l32> li:first-child>*:first-child:before {counter-increment: n3 0;  }
#l33 {padding-left: 0pt;counter-reset: n4 1; }
#l33> li>*:first-child:before {counter-increment: n4; content: counter(n4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l33> li:first-child>*:first-child:before {counter-increment: n4 0;  }
li {display: block; }
#l34 {padding-left: 0pt;counter-reset: p1 4; }
#l34> li>*:first-child:before {counter-increment: p1; content: counter(p1, decimal)" "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l34> li:first-child>*:first-child:before {counter-increment: p1 0;  }
#l35 {padding-left: 0pt;counter-reset: p2 1; }
#l35> li>*:first-child:before {counter-increment: p2; content: counter(p1, decimal)"."counter(p2, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l35> li:first-child>*:first-child:before {counter-increment: p2 0;  }
#l36 {padding-left: 0pt;counter-reset: p3 1; }
#l36> li>*:first-child:before {counter-increment: p3; content: counter(p1, decimal)"."counter(p2, decimal)"."counter(p3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l36> li:first-child>*:first-child:before {counter-increment: p3 0;  }
#l37 {padding-left: 0pt;counter-reset: p3 1; }
#l37> li>*:first-child:before {counter-increment: p3; content: counter(p1, decimal)"."counter(p2, decimal)"."counter(p3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l37> li:first-child>*:first-child:before {counter-increment: p3 0;  }
li {display: block; }
#l38 {padding-left: 0pt;counter-reset: q1 5; }
#l38> li>*:first-child:before {counter-increment: q1; content: counter(q1, decimal)" "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l38> li:first-child>*:first-child:before {counter-increment: q1 0;  }
#l39 {padding-left: 0pt;counter-reset: q2 1; }
#l39> li>*:first-child:before {counter-increment: q2; content: counter(q1, decimal)"."counter(q2, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l39> li:first-child>*:first-child:before {counter-increment: q2 0;  }
#l40 {padding-left: 0pt;counter-reset: q3 1; }
#l40> li>*:first-child:before {counter-increment: q3; content: counter(q1, decimal)"."counter(q2, decimal)"."counter(q3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l40> li:first-child>*:first-child:before {counter-increment: q3 0;  }
#l41 {padding-left: 0pt;counter-reset: q3 1; }
#l41> li>*:first-child:before {counter-increment: q3; content: counter(q1, decimal)"."counter(q2, decimal)"."counter(q3, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l41> li:first-child>*:first-child:before {counter-increment: q3 0;  }
#l42 {padding-left: 0pt;counter-reset: q4 1; }
#l42> li>*:first-child:before {counter-increment: q4; content: counter(q4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l42> li:first-child>*:first-child:before {counter-increment: q4 0;  }
#l43 {padding-left: 0pt;counter-reset: q4 1; }
#l43> li>*:first-child:before {counter-increment: q4; content: counter(q4, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l43> li:first-child>*:first-child:before {counter-increment: q4 0;  }
li {display: block; }
#l44 {padding-left: 0pt;counter-reset: r1 6; }
#l44> li>*:first-child:before {counter-increment: r1; content: counter(r1, decimal)" "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l44> li:first-child>*:first-child:before {counter-increment: r1 0;  }
#l45 {padding-left: 0pt;counter-reset: r2 1; }
#l45> li>*:first-child:before {counter-increment: r2; content: counter(r1, decimal)"."counter(r2, decimal)" "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l45> li:first-child>*:first-child:before {counter-increment: r2 0;  }
li {display: block; }
#l46 {padding-left: 0pt;counter-reset: s1 1; }
#l46> li>*:first-child:before {counter-increment: s1; content: "("counter(s1, decimal)") "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: bold; text-decoration: none; font-size: 11pt; }
#l46> li:first-child>*:first-child:before {counter-increment: s1 0;  }
#l47 {padding-left: 0pt;counter-reset: s2 1; }
#l47> li>*:first-child:before {counter-increment: s2; content: counter(s2, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l47> li:first-child>*:first-child:before {counter-increment: s2 0;  }
#l48 {padding-left: 0pt;counter-reset: s3 1; }
#l48> li>*:first-child:before {counter-increment: s3; content: counter(s3, lower-roman)". "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l48> li:first-child>*:first-child:before {counter-increment: s3 0;  }
li {display: block; }
#l49 {padding-left: 0pt;counter-reset: t1 1; }
#l49> li>*:first-child:before {counter-increment: t1; content: counter(t1, decimal)". "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l49> li:first-child>*:first-child:before {counter-increment: t1 0;  }
li {display: block; }
#l50 {padding-left: 0pt;counter-reset: u1 6; }
#l50> li>*:first-child:before {counter-increment: u1; content: counter(u1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 10pt; }
#l50> li:first-child>*:first-child:before {counter-increment: u1 0;  }
li {display: block; }
#l51 {padding-left: 0pt;counter-reset: v1 1; }
#l51> li>*:first-child:before {counter-increment: v1; content: counter(v1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l51> li:first-child>*:first-child:before {counter-increment: v1 0;  }
#l52 {padding-left: 0pt;counter-reset: v2 1; }
#l52> li>*:first-child:before {counter-increment: v2; content: counter(v2, lower-latin)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l52> li:first-child>*:first-child:before {counter-increment: v2 0;  }
li {display: block; }
#l53 {padding-left: 0pt;counter-reset: w1 7; }
#l53> li>*:first-child:before {counter-increment: w1; content: counter(w1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l53> li:first-child>*:first-child:before {counter-increment: w1 0;  }
li {display: block; }
#l54 {padding-left: 0pt;counter-reset: x1 1; }
#l54> li>*:first-child:before {counter-increment: x1; content: counter(x1, decimal)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l54> li:first-child>*:first-child:before {counter-increment: x1 0;  }
li {display: block; }
#l55 {padding-left: 0pt;counter-reset: y1 2; }
#l55> li>*:first-child:before {counter-increment: y1; content: counter(y1, decimal)". "; color: black; font-style: normal; font-weight: normal; text-decoration: none; }
#l55> li:first-child>*:first-child:before {counter-increment: y1 0;  }
#l56 {padding-left: 0pt;counter-reset: y2 1; }
#l56> li>*:first-child:before {counter-increment: y2; content: counter(y2, lower-roman)". "; color: black; font-family:Arial, sans-serif; font-style: normal; font-weight: normal; text-decoration: none; font-size: 11pt; }
#l56> li:first-child>*:first-child:before {counter-increment: y2 0;  }