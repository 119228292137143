
.s1 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
    vertical-align: 4pt;
}
h1 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}
.p,
p {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
    margin: 0pt;
}
.s2 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: underline;
    font-size: 10pt;
}
.s3 {
    color: black;
    font-family: "Times New Roman", serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
}
.s4 {
    color: #153d62;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
}
.s6 {
    color: #153d62;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: underline;
    font-size: 10pt;
}
.a {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
.s7 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
    vertical-align: 4pt;
}
.s8 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 6.5pt;
    vertical-align: 3pt;
}
.s9 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 1pt;
}
.s10 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 10pt;
}
.s11 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 11pt;
}
.s12 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 14pt;
}
.s13 {
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: bold;
    text-decoration: none;
    font-size: 11pt;
}
.s14 {
    color: black;
    font-family: Calibri, sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: none;
    font-size: 8pt;
}
.s15 {
    color: #00f;
    font-family: Calibri, sans-serif;
    font-style: italic;
    font-weight: normal;
    text-decoration: underline;
    font-size: 8pt;
}
li {
    display: block;
}
#l1 {
    padding-left: 0pt;
    counter-reset: c1 1;
}
#l1 > li > *:first-child:before {
    counter-increment: c1;
    content: counter(c1, decimal) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l1 > li:first-child > *:first-child:before {
    counter-increment: c1 0;
}
#l2 {
    padding-left: 0pt;
    counter-reset: c2 1;
}
#l2 > li > *:first-child:before {
    counter-increment: c2;
    content: counter(c2, lower-latin) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l2 > li:first-child > *:first-child:before {
    counter-increment: c2 0;
}
#l3 {
    padding-left: 0pt;
    counter-reset: c2 1;
}
#l3 > li > *:first-child:before {
    counter-increment: c2;
    content: counter(c2, lower-latin) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l3 > li:first-child > *:first-child:before {
    counter-increment: c2 0;
}
li {
    display: block;
}
#l4 {
    padding-left: 0pt;
    counter-reset: d1 1;
}
#l4 > li > *:first-child:before {
    counter-increment: d1;
    content: "(" counter(d1, decimal) ") ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l4 > li:first-child > *:first-child:before {
    counter-increment: d1 0;
}
li {
    display: block;
}
#l5 {
    padding-left: 0pt;
    counter-reset: e1 1;
}
#l5 > li > *:first-child:before {
    counter-increment: e1;
    content: counter(e1, decimal) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l5 > li:first-child > *:first-child:before {
    counter-increment: e1 0;
}
#l6 {
    padding-left: 0pt;
    counter-reset: e2 1;
}
#l6 > li > *:first-child:before {
    counter-increment: e2;
    content: counter(e2, lower-latin) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l6 > li:first-child > *:first-child:before {
    counter-increment: e2 0;
}
li {
    display: block;
}
#l7 {
    padding-left: 0pt;
    counter-reset: f1 1;
}
#l7 > li > *:first-child:before {
    counter-increment: f1;
    content: counter(f1, decimal) ". ";
    color: black;
    font-family: Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l7 > li:first-child > *:first-child:before {
    counter-increment: f1 0;
}
li {
    display: block;
}
#l8 {
    padding-left: 0pt;
}
#l8 > li > *:first-child:before {
    content: "☐ ";
    color: black;
    font-family: "Segoe UI Symbol", sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l9 {
    padding-left: 0pt;
    counter-reset: g2 1;
}
#l9 > li > *:first-child:before {
    counter-increment: g2;
    content: counter(g2, upper-latin) ". ";
    color: black;
    font-family: Calibri, sans-serif;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 10pt;
}
#l9 > li:first-child > *:first-child:before {
    counter-increment: g2 0;
}
table,
tbody {
    vertical-align: top;
    overflow: visible;
}